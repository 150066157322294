.main-wrapper {

    // .main-wrapper__section

    &__section {
    }
}
.advantages {
    z-index: 7;
    position: sticky;
    // .advantages__container

    &__container {
    }

    // .advantages__inner

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    // .advantages__main-text

    &__main-text {
        text-align: center;
        color: var(--light-color);
        font-size: 40px;
        font-weight: 600;
        @include xxxl{
            font-size: 35px;
        }
        @include xl{
            font-size: 30px;  
            
        }
       
    }

    // .advantages__block

    &__block {
        
        

        width: 1360px;
        margin-top: 150px;
        border-radius: 15px;
        background-color: rgba(53, 49, 49, 0.5);
        @include xxxxxl{
            width: 100%;
            text-align: center;   
        }
        @include md{
            margin-top: 80px;
         }
    }

    // .advantages__block-cards

    &__block-cards {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 20px;
        padding: 30px;
        padding-top: 0px;
    }

    // .advantages__card

    &__card {
        grid-column: 4 span;
        background-color: rgba(88, 74, 74, 0.678);
        border-radius: 15px;
        //width: 400px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include md{
           grid-column: 12 span;
           }

    }

    // .advantages__card-img

    &__card-img {
        width: 20%;
        height: auto;
      padding-top: 20px;
    }

    // .advantages__text-main

    &__text-main {
        color: var(--light-color);
        font-size: 25px;
        margin: 20px 0px 10px 0px;
        font-weight: 500;
        @include xxxl{
            font-size: 20px;
        }
        @include xl{
            font-size: 16px;  
            
        }
    }

    // .advantages__text-descr

    &__text-descr {
      text-align: center;
      color: var(--light-color);
      font-size: 18px;
      padding: 0px 20px 0px 20px;
      margin-top: 10px;
      font-weight: 400;
      @include xxxl{
        font-size: 16px;
    }
    @include xl{
        font-size: 14px;  
        
    }
    }
    
}
.container {
}
