.main-wrapper {

    // .main-wrapper__section

    &__section {
    }
}
.main {
    z-index: 10;
    position: sticky;
    // .main__container

    &__container {
    }

    // .main__inner

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        
    }

    // .main__block

    &__block {
        width: 1360px;
        margin-top: 150px;
        border-radius: 15px;
        background-color: rgba(53, 49, 49, 0.5);
        @include xxxl{
           width: 100%; 
        }
    }

    // .main__main-text

    &__main-text {
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        @include xxxl{
         font-size: 18px;   
         font-weight: 600;
        }
        @include xl{
            font-size: 16px;
            font-weight: 500;
        }
        @include md{
            font-size: 14px;
            text-align: center;
        }
        @include md{
            font-size: 10px;
         }
    }

    // .main__text

    &__text {
        color: #ffff;
        font-weight: 600;
        
    }
    &__text-descr{
        color:  #ffff;
        font-size: 20px;
        margin: 15px 0px 15px 0px;
        @include xl{
            font-size: 16px;
        }
        @include md{
            font-size: 14px;
        }
        @include md{
            font-size: 12px;
         }
    }
    #yellow{
        color: var(--yellow-color);

    }
    #main-w{
        width: 500px;
        @include xxxl{
         width: 100%;   
        }
    }
    &__linia {
      width: 160px;
      height: 1px;
      border-top: 2px solid var(--yellow-color);
      @include md{
        width: 60%;
    }
    @include md{
        width: 80%;
        
        margin-left: 35px;
     }
    }
    // .main__block-descr

    &__block-descr {
        display: flex;
        align-items: center;
        @include md{
            display: block;
         }
    }

    // .main__descr-img

    &__descr-img {
        width: 500px;
        height: auto;
        border-radius: 0px 15px 0px 15px;
        @include xxxl{
           max-width: 450px;
            height: auto;
        }
        @include xl{
            max-width: 320px;
        }
        @include md{
            max-width: 220px;
            width: 100%;
        }
        @include md{
            max-width: 420px;
            width: 100%;
           
            border-radius: 0px;
         }
    }

    // .main__img

    &__img {
        border-radius: 0px 15px 0px 15px;
    }

    // .main__descr-text

    &__descr-text {
        margin-left: 100px;
        @include xxxl{
         margin-left: 50px;   
        }
        @include xl{
            margin-left: 25px;
        }
        @include md{
            margin-left: 0px;
           text-align: center;
         }
    }

    // .main__linia

   
}
.container {
}
