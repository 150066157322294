.main-wrapper {

    // .main-wrapper__section

    &__section {
    }
}
.news1 {
    z-index: 10;
    position: sticky;
    padding-bottom: 100px;
    // .news1__container

    &__container {
    }

    // .news1__inner

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    // .news1__block

    &__block {
       // display: flex;
        justify-content: center;
        

        width: 1360px;
        margin-top: 150px;
        border-radius: 15px;
        background-color: rgb(53 49 49 / 89%);
        @include xxxxxl{
            width: 100%;
        }
        @include lg{
            display: block;
        }
        @include md{
           margin-top: 80px;
        }
    }

    // .news1__block-descr

    &__block-descr {
        color: var(--light-color);
        width: 100%;
    }

    // .news1__main-text

    &__main-text {
        text-align: center;
       font-size: 40px;
       margin: 20px 0px 20px 0px;
       font-weight: 600;
       @include xxxl{
        font-size: 35px; 
         
       }
       @include xl{
        font-size: 30px;   
        font-weight: 600;
       }
       @include md{
        font-size: 25px;
    }
    @include sm{
        font-size: 20px;
       }
    }

    // .news1__descr-text

    &__descr-text {
        font-size: 20px;
        padding: 20px 20px 0px 20px;
        font-weight: 400;
        text-align: justify;
       line-height: 1.2;
       @include xxxxxl {
        line-height: 1.5;
       }
       @include xxxl{
        font-size: 18px;
            line-height: 1.5
       }
       @include xl{
        font-size: 16px;   
       }
       @include md{
        font-size: 14px;
    }
    @include sm{
        font-size: 12px;
       }
    }
}
.container {
}
.autor {

    // .autor__descr-img

    &__descr-img {
    }
}
