.footer {
    position: sticky;
    background-color: #000000;
     border-top: 2px solid rgb(33, 41, 65);
    // .footer__container

    &__container {
    }

    // .footer__content

    &__content {
        color: white;
    }

    // .footer__block

    &__block {
    }

    // .footer__logo

    &__logo {
    }

    // .footer__connection

    &__connection {
       display: grid;
       gap: 15px;
        grid-column: 6 span;
        @include md{
            display: flex;
            flex-direction: column;
            align-items: start;
        }
    }

    // .footer__qestion

    &__qestion {
        grid-column-start: 2;
        grid-column-end: 3;
        @include sm{
            grid-column: 6;
            
        }
    }

    // .footer__main-text

    &__main-text {
        font-size: 22px;
        width: 300px;
        @include xxxl{
            font-size: 20px;
           }
           @include xxl{
            font-size:  18px;
            
           }@include md {
            font-size: 14px;
      
          }
          @include sm{
            
            
          }
    }

    // .footer__form

    &__form {
        width: 400px;
        @include xl{
            width: 300px; 
            
        }
        @include md{
            width: 200px; 
            
        }
    }

    // .footer__info-input

    &__info-input {
        border-radius: 50px;
        border: 2px solid #e9c62e;
        height: 40px;
        width: 400px;
        margin-top: 10px;
        &[type="text"] {
            padding-left: 20px;
          }
          &[type="email"] {
            padding-left: 20px;
          }
    }
    &__info-input:nth-child(2){
        width: 400px;
        height: 40px;
        @include xl{
            width: 300px; 
            
        }
        @include md {
            width: 300px;
            height: 30px;
          }
         
    }
    &__info-input:nth-child(1){
        width: 300px;
        @include xl{
            width: 200px; 
            
        }
        @include md {
            width: 300px;
            height: 30px;
          }
          
    }

    // .footer__button-email

    &__button-email {
        margin-top: 10px;
        display: flex;
        justify-content: center;
       padding: 10px;
      
    }

    // .footer__button

    &__button {
        border-radius: 100px;
        background-color: transparent;
        color:#e9c62e;
        border: 1px solid #e9c62e;
        height: 40px;
        width: 200px;
        
        transition: background-color 0.5s;
        &:hover{
            background-color: #e9c62e;
            color: black;
        }
        @include xl{
            width: 160px;
            font-size: 16px; 
            height: 30px;
            
        }
        @include md{
            font-size: 14px;
            height: 30px;
            width: 140px;
            
        }
    }

    // .footer__text-email

    &__text-email {
        grid-column-start: 5;
        grid-column-end: 8;
        @include xxxl{
            grid-column-start:4 ;
            grid-column-end: 7;
        }
        @include xl {
            grid-column-start: 3;
        grid-column-end: 7;
           }
           @include md{
           // margin-left: -40px;
           
           }
           @include sm{
            
            grid-column: 6;
           }
           
    }

    // .footer__social-network

    &__social-network {
       margin-left: 50px;
        grid-column-start: 9;
        grid-column-end: 13;
        @include xxxl{
            font-size: 20px;
            grid-column-start: 8;
        grid-column-end: 13;
           }
           @include xxl{
            font-size:  18px;
            
           }
           @include xl {
            font-size: 16px;
            margin-left: 0px;
           }
           @include xl {
            grid-column-start: 7;
        grid-column-end: 13;
           }
           @include md {
            font-size: 14px;
      
          }
          @include md{
            margin-left: 0px;
           }
           @include sm{
            grid-column-start: 7;
            grid-column-end: 13;
           
           }
    }

    // .footer__text-social

    &__text-social {
        font-size: 24px;
        @include xxxxl {
            font-size: 24px;
           }
           @include xxxl{
            font-size: 20px;
           }
           @include xxl{
            font-size:  18px;
            
           }
           @include xl {
            font-size: 16px;
           }
           @include md {
            font-size: 14px;
      
          }
    }

    // .footer__follow-list

    &__follow-list {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 10px;
        margin-left: 30px;
        @include xl {
            margin-left: 0px;
           }
           @include md{
            margin-left: 0px;
            padding-left: 0px;
           }
           
    }

    // .footer__follow-item

    &__follow-item {
        
    }

    // .footer__follow-link

    &__follow-link {
       
        border-radius: 50px;
        display: inline-block;
        height: 30px;
       
       
        width: 30px;
        &:focus{
            outline: none; /* убирает пунктирную обводку при focus */

        }
       
       
    }
    &__svg-link{
        margin-bottom: 12px;
       height: 30px;
       width: 30px;
       @include md{
        height: 20px;
        width: 20px;
       }
    }
}
.container {
}
.footer-inner {
}
.footer-logo {

    // .footer-logo__logo

    &__logo {
        font-family: 'solena';
        font-weight: 400;
        font-size: 48px;
        padding: 10px;
        @include xxxl{
            font-size: 40px;
           }
           @include xxl{
            font-size:  36px;
            
           }
           @include xl {
            font-size: 24px;
           }
    }
}
.footer-email {
    
    @include sm{
        //margin-left: 10px;
       }
    // .footer-email__text

    &__text {
        font-size: 24px;
        @include xxxl{
            font-size: 20px;
           }
           @include xxl{
            font-size:  18px;
            
           }
           @include xl {
            font-size: 16px;
           }
           @include md {
            font-size: 14px;
            
      
          }
    }

    // .footer-email__email

    &__email {
        font-size: 22px;
        @include xxxl{
            font-size: 18px;
           }
           @include xxl{
            font-size:  16px;
            
           }
           @include xl {
            font-size: 14px;
           }
           @include md {
            font-size: 12px;
      
          }
        &:focus{
        outline: none; /* убирает пунктирную обводку при focus */
        }
    }
}
.bx {
    color: #e9c62e;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 5px;
    margin-left: -2px;
    font-size: 24px;

   text-decoration: none;
   @include md{
    font-size: 18px;
    margin-top: 2px;
   }
       
}
a:hover{
    
   text-decoration: none;
   outline: none; 
   color: inherit; /* наследует цвет текста */
}

.bxl-behance {
}
.bxl-telegram {
}
.bxl-tiktok {
}
