body{
    background-color: #000000;
    line-height: 1.2;
    
}
html{
    background-color: #000000;
    scroll-behavior: smooth;
}

.application {

    // .application__header

    &__header {
    }
}
.header {
    z-index: 13;
    position: fixed;
    top: 0;
    scroll-behavior: smooth;
    width: 100%;
    padding: 20px 0px 20px 0px;
    background-color:  var(--black-color);
    border-bottom: 2px solid #1e1b3d;

    &__container {
    }

    // .header__inner

    &__inner {
        
        
    }
    

    // .header__content

    &__content {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 100px;
        
    }

    // .header__content-logo

    &__content-logo {
    }

    // .header__logo-img

    &__logo-img {
    }

    // .header__logo

    &__logo {
        height: auto;
        width: 285px;
        cursor: pointer;
    }

    // .header__content-navbar

    &__content-navbar {
    }
}
.container {
}
.navbar {
   
    // .navbar__menu

    &__menu {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style-type: none;
        gap: 40px;
        margin: 10px 0px 16px 0px;
        font-family: 'Inter';
        font-weight: 500;
        font-size: 20px;
        
    }

    // .navbar__item

    &__item {
        color: var(--light-color);
        transition: all 200ms;
    }

    &__item:hover {
        color: var(--gold-color);
        
    }
    // .navbar__link

    &__link {
        cursor: pointer;
        color: var(--light-color);
        transition: all 300ms;
    }
    
}
.hamburger-menu {
}
.menu {

    // .menu__box

    &__box {
    }

    // .menu__item--burger

    &__item--burger {
    }
}

#menu__toggle{
    display: none;
}

@include xxxl{
    #menu__toggle{
        display: block;
    }
    


#menu__toggle {
    opacity: 0;
  }
  #menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
  }
  #menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle:checked ~ .menu__box {
    left: 75% !important;
    @include lg{
        left: 62% !important;
    }
    @include md{
        left: 52% !important;
    }
    @include gg{
        left: 40% !important;
    }
  }
  .menu__btn {
    position: fixed;
    top: 33px;
    right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transition-duration: .25s;
  }
  .menu__btn > span::before {
    content: '';
    top: -8px;
  }
  .menu__btn > span::after {
    content: '';
    top: 8px;
  }
  .menu__box {
    display: block;
    position: fixed;
    top: 0;
    left: 100%;
    width: 300px;
    height: 100%;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    background-color: #000;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .25s;
  }
  .menu__item {
    display: block;
    padding: 12px 24px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
  }
  .menu__item:hover {
    background-color: #252525;
  }

  

}