// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Muller", sans-serif;
  --content-width: 1440px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --black-color: #000;
  --card-color: rgba(53, 49, 49, 0.5);
  --yellow-color: #FFF600;
  --gold-color: #e9c62e;
}
