.application {

    // .application__header

    &__header {
    }
}
.header-blog {
    z-index: 13;
    position: fixed;
    top: 0;
    scroll-behavior: smooth;
    width: 100%;
    padding: 20px 0px 20px 0px;
    background-color:  var(--black-color);
    border-bottom: 2px solid #1e1b3d;
    // .header-blog__container

    &__container {
    }

    // .header-blog__inner

    &__inner {
    }

    // .header-blog__content

    &__content {
       //display: flex;
       //justify-content: start;
       //align-items: center;
       //gap: 300px;
    }

    // .header-blog__content-logo

    &__content-logo {
    }

    // .header-blog__logo-img

    &__logo-img {
    }

    // .header-blog__logo

    &__logo {
      height: auto;
      width: 285px;
      cursor: pointer;
    }

    // .header-blog__content-navbar

    &__content-navbar {
    }
}
.container {
}
.navbar-blog {
padding: 0px;
    // .navbar__menu

    &__menu {
        padding: 0px;
        margin-right: 290px;
        display: flex;
        justify-content: center;
        align-items: center;
        list-style-type: none;
        
        
        font-family: 'Inter';
        font-weight: 500;
        font-size: 20px;
        @include lg{
            margin-right: 0px;
            font-size: 16px;
        }
    }

    // .navbar__item

    &__item {
        color: var(--light-color);
        transition: all 200ms;
    }
    &__item:hover {
        color: var(--gold-color);
        
    }

    // .navbar__link

    &__link {
        cursor: pointer;
        color: var(--light-color);
        transition: all 300ms;
    }
}
.menu {
   
    // .menu__btn

    &__btn {
    }

    // .menu__box

    &__box {
       
    }

    // .menu__item

    &__item {
    }
}
.hamburger-menu {
}

.header-blog__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
 }
 
 .header-blog__content-logo {
    margin-right: 20px;
 }
 
 .header-blog__logo-img {
    display: flex;
    justify-content: center;
    align-items: center;
 }
 
 .header-blog__logo {
   
 }
 
 .header-blog__content-navbar {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 
 .navbar-blog__menu {
    display: flex;
    justify-content: center;
    align-items: center;
 }
 
 .navbar-blog__item {
    margin-right: 20px;
 }
 
 .navbar-blog__link {
    
    
 }
 
 .menu__item:hover {
    
 }
 
 .navbar-blog__link:focus {
    outline: none;
 }