/* stylelint-disable */
@mixin font-face($font-family, $url, $weight, $style) {
  @font-face {
    font-family: "#{$font-family}";
    src: url('../fonts/#{$url}.woff2') format("woff2");
    font-weight: #{$weight};
    font-display: swap;
    font-style: $style;
  }
  @font-face {
    font-family: 'Inter';
    src: url(../fonts/Inter-LightBETA.woff2) format(woff2);
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url(../fonts/Inter-Regular.woff2) format(woff2);
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Solena';
    src: url(../fonts/Solena.woff2) format(woff2);
    font-weight: 400;
    font-style: normal;
}

  
}







