@charset "UTF-8";
:root {
  --font-family: "Muller", sans-serif;
  --content-width: 1440px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
  --black-color: #000;
  --card-color: rgba(53, 49, 49, 0.5);
  --yellow-color: #FFF600;
  --gold-color: #e9c62e;
}

/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Muller";
  src: url("../fonts/../fonts/MullerRegular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url(../fonts/Inter-LightBETA.woff2) format(woff2);
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url(../fonts/Inter-Regular.woff2) format(woff2);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Solena";
  src: url(../fonts/Solena.woff2) format(woff2);
  font-weight: 400;
  font-style: normal;
}
html {
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--light-color);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  width: 100vw;
  overflow-x: hidden;
  font-family: var(--font-family);
  background-color: var(--light-color);
  color: var(--black-color);
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: inherit;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

body {
  background-color: #000000;
  line-height: 1.2;
}

html {
  background-color: #000000;
  scroll-behavior: smooth;
}

.header {
  z-index: 13;
  position: fixed;
  top: 0;
  scroll-behavior: smooth;
  width: 100%;
  padding: 20px 0px 20px 0px;
  background-color: var(--black-color);
  border-bottom: 2px solid #1e1b3d;
}
.header__content {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 100px;
}
.header__logo {
  height: auto;
  width: 285px;
  cursor: pointer;
}
.navbar__menu {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  gap: 40px;
  margin: 10px 0px 16px 0px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
}
.navbar__item {
  color: var(--light-color);
  transition: all 200ms;
}
.navbar__item:hover {
  color: var(--gold-color);
}
.navbar__link {
  cursor: pointer;
  color: var(--light-color);
  transition: all 300ms;
}

#menu__toggle {
  display: none;
}

@media (max-width: 1024px) {
  #menu__toggle {
    display: block;
  }
  #menu__toggle {
    opacity: 0;
  }
  #menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
  }
  #menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle:checked ~ .menu__box {
    left: 75% !important;
  }
}
@media (max-width: 1024px) and (max-width: 768px) {
  #menu__toggle:checked ~ .menu__box {
    left: 62% !important;
  }
}
@media (max-width: 1024px) and (max-width: 576px) {
  #menu__toggle:checked ~ .menu__box {
    left: 52% !important;
  }
}
@media (max-width: 1024px) and (max-width: 375px) {
  #menu__toggle:checked ~ .menu__box {
    left: 40% !important;
  }
}
@media (max-width: 1024px) {
  .menu__btn {
    position: fixed;
    top: 33px;
    right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transition-duration: 0.25s;
  }
  .menu__btn > span::before {
    content: "";
    top: -8px;
  }
  .menu__btn > span::after {
    content: "";
    top: 8px;
  }
  .menu__box {
    display: block;
    position: fixed;
    top: 0;
    left: 100%;
    width: 300px;
    height: 100%;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    background-color: #000;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    transition-duration: 0.25s;
  }
  .menu__item {
    display: block;
    padding: 12px 24px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: 0.25s;
  }
  .menu__item:hover {
    background-color: #252525;
  }
}
.advantages {
  z-index: 7;
  position: sticky;
}
.advantages__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.advantages__main-text {
  text-align: center;
  color: var(--light-color);
  font-size: 40px;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .advantages__main-text {
    font-size: 35px;
  }
}
@media (max-width: 820px) {
  .advantages__main-text {
    font-size: 30px;
  }
}
.advantages__block {
  width: 1360px;
  margin-top: 150px;
  border-radius: 15px;
  background-color: rgba(53, 49, 49, 0.5);
}
@media (max-width: 1360px) {
  .advantages__block {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .advantages__block {
    margin-top: 80px;
  }
}
.advantages__block-cards {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  padding: 30px;
  padding-top: 0px;
}
.advantages__card {
  grid-column: 4 span;
  background-color: rgba(88, 74, 74, 0.678);
  border-radius: 15px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 576px) {
  .advantages__card {
    grid-column: 12 span;
  }
}
.advantages__card-img {
  width: 20%;
  height: auto;
  padding-top: 20px;
}
.advantages__text-main {
  color: var(--light-color);
  font-size: 25px;
  margin: 20px 0px 10px 0px;
  font-weight: 500;
}
@media (max-width: 1024px) {
  .advantages__text-main {
    font-size: 20px;
  }
}
@media (max-width: 820px) {
  .advantages__text-main {
    font-size: 16px;
  }
}
.advantages__text-descr {
  text-align: center;
  color: var(--light-color);
  font-size: 18px;
  padding: 0px 20px 0px 20px;
  margin-top: 10px;
  font-weight: 400;
}
@media (max-width: 1024px) {
  .advantages__text-descr {
    font-size: 16px;
  }
}
@media (max-width: 820px) {
  .advantages__text-descr {
    font-size: 14px;
  }
}

.autor {
  z-index: 10;
  position: sticky;
}
.autor__list {
  text-align: start;
}
.autor__list-text {
  font-size: 20px;
  padding: 5px;
  list-style-type: square;
}
@media (max-width: 1024px) {
  .autor__list-text {
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .autor__list-text {
    font-size: 16px;
  }
}
@media (max-width: 430px) {
  .autor__list-text {
    font-size: 14px;
  }
}
.autor__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.autor__block {
  display: flex;
  justify-content: center;
  width: 1360px;
  margin-top: 150px;
  border-radius: 15px;
  background-color: rgba(53, 49, 49, 0.5);
}
@media (max-width: 1360px) {
  .autor__block {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .autor__block {
    display: block;
  }
}
@media (max-width: 576px) {
  .autor__block {
    margin-top: 80px;
  }
}
.autor__block-descr {
  color: var(--light-color);
  width: 100%;
}
.autor__main-text {
  text-align: center;
  font-size: 40px;
  margin: 20px 0px 5px 0px;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .autor__main-text {
    font-size: 35px;
  }
}
@media (max-width: 820px) {
  .autor__main-text {
    font-size: 30px;
    font-weight: 600;
  }
}
@media (max-width: 576px) {
  .autor__main-text {
    font-size: 25px;
  }
}
@media (max-width: 430px) {
  .autor__main-text {
    font-size: 20px;
  }
}
.autor__descr-text {
  font-size: 20px;
  padding: 20px 20px 0px 20px;
  font-weight: 400;
  text-align: justify;
  line-height: 1.2;
}
@media (max-width: 1360px) {
  .autor__descr-text {
    line-height: 1.5;
  }
}
@media (max-width: 1024px) {
  .autor__descr-text {
    font-size: 18px;
    line-height: 1.5;
  }
}
@media (max-width: 820px) {
  .autor__descr-text {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .autor__descr-text {
    font-size: 14px;
  }
}
@media (max-width: 430px) {
  .autor__descr-text {
    font-size: 12px;
  }
}
.autor__descr-img {
  border-radius: 15px;
  object-fit: cover;
  width: 980px;
  height: 736px;
}
@media (max-width: 1360px) {
  .autor__descr-img {
    height: auto;
  }
}
@media (max-width: 1024px) {
  .autor__descr-img {
    width: 400px;
  }
}
@media (max-width: 820px) {
  .autor__descr-img {
    width: 300px;
  }
}
@media (max-width: 768px) {
  .autor__descr-img {
    width: 100%;
    max-height: 800px;
  }
}
@media (max-width: 576px) {
  .autor__descr-img {
    width: 100%;
    max-height: 600px;
  }
}
.autor #obraz {
  margin-top: 50px;
}

.title {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 100%;
  color: #fff;
  font-weight: 100;
  font-size: 3em;
  font-family: "Pacifico", cursive;
  position: fixed;
}

.title {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 100%;
  background-size: 100%;
  position: fixed;
}

.noite {
  background: -webkit-linear-gradient(top, rgb(0, 0, 0) 50%, rgb(25, 19, 39) 80%, rgb(43, 32, 72));
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  position: fixed;
}

.constelacao {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}

.estrela {
  background-color: white;
  border-radius: 50%;
  position: absolute;
  animation-name: estrela;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: fixed;
}

.estrela.tam1 {
  width: 1px;
  height: 1px;
}

.estrela.tam2 {
  width: 2px;
  height: 2px;
}

.estrela.tam3 {
  width: 3px;
  height: 3px;
}

.estrela.opacity1 {
  opacity: 1;
}

.estrela.opacity2 {
  opacity: 0.5;
}

.estrela.opacity3 {
  opacity: 0.1;
}

.meteoro {
  position: absolute;
  background-color: #fff;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  transform: rotate(-35deg);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  position: fixed;
}

.meteoro:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 0;
  height: 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 85px solid white;
  position: absolute;
  left: 2px;
  top: 0;
}

.meteoro.style1 {
  animation-name: meteoroStyle1;
}

.meteoro.style2 {
  animation-name: meteoroStyle2;
}

.meteoro.style3 {
  animation-name: meteoroStyle3;
}

.meteoro.style4 {
  animation-name: meteoroStyle4;
}

.lua {
  position: absolute;
  right: 200px;
  top: 150px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0 0 160px 0px #fff, 0 0 30px -4px #fff, 0 0 8px 2px rgba(255, 255, 255, 0.26);
  background-color: #fff;
  animation-name: lua;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  position: fixed;
}

.lua .textura {
  background-image: url(https://raw.githubusercontent.com/interaminense/starry-sky/master/src/img/bgMoon.png);
  background-position: center;
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  overflow: hidden;
  opacity: 0.5;
}

@keyframes escurecer {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}
@keyframes estrela {
  0% {
    box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.05);
  }
  50% {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.05);
  }
}
@keyframes estrelaDestacada {
  0% {
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 0px rgb(255, 255, 255);
  }
  20% {
    background-color: #FFC4C4;
    box-shadow: 0 0 10px 0px rgb(255, 196, 196);
  }
  80% {
    background-color: #C4CFFF;
    box-shadow: 0 0 10px 0px rgb(196, 207, 255);
  }
  100% {
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.2);
  }
}
@keyframes meteoroStyle1 {
  0% {
    opacity: 0;
    right: 300px;
    top: 100px;
  }
  30% {
    opacity: 0.3;
  }
  60% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    right: 1000px;
    top: 600px;
  }
}
@keyframes meteoroStyle2 {
  0% {
    opacity: 0;
    right: 700px;
    top: 100px;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    right: 1400px;
    top: 600px;
  }
}
@keyframes meteoroStyle3 {
  0% {
    opacity: 0;
    right: 300px;
    top: 300px;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    right: 1000px;
    top: 800px;
  }
}
@keyframes meteoroStyle4 {
  0% {
    opacity: 0;
    right: 700px;
    top: 300px;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    right: 1400px;
    top: 800px;
  }
}
@keyframes lua {
  0% {
    box-shadow: 0 0 160px 0px #fff, 0 0 30px -4px #fff, 0 0 8px 2px rgba(255, 255, 255, 0.26);
  }
  50% {
    box-shadow: 0 0 80px 0px #fff, 0 0 30px -4px #fff, 0 0 8px 2px rgba(255, 255, 255, 0.26);
  }
  100% {
    box-shadow: 0 0 160px 0px #fff, 0 0 30px -4px #fff, 0 0 8px 2px rgba(255, 255, 255, 0.26);
  }
}
/*
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
*/
.footer {
  position: sticky;
  background-color: #000000;
  border-top: 2px solid rgb(33, 41, 65);
}
.footer__content {
  color: white;
}
.footer__connection {
  display: grid;
  gap: 15px;
  grid-column: 6 span;
}
@media (max-width: 576px) {
  .footer__connection {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}
.footer__qestion {
  grid-column-start: 2;
  grid-column-end: 3;
}
@media (max-width: 430px) {
  .footer__qestion {
    grid-column: 6;
  }
}
.footer__main-text {
  font-size: 22px;
  width: 300px;
}
@media (max-width: 1024px) {
  .footer__main-text {
    font-size: 20px;
  }
}
@media (max-width: 960px) {
  .footer__main-text {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .footer__main-text {
    font-size: 14px;
  }
}
.footer__form {
  width: 400px;
}
@media (max-width: 820px) {
  .footer__form {
    width: 300px;
  }
}
@media (max-width: 576px) {
  .footer__form {
    width: 200px;
  }
}
.footer__info-input {
  border-radius: 50px;
  border: 2px solid #e9c62e;
  height: 40px;
  width: 400px;
  margin-top: 10px;
}
.footer__info-input[type=text] {
  padding-left: 20px;
}
.footer__info-input[type=email] {
  padding-left: 20px;
}
.footer__info-input:nth-child(2) {
  width: 400px;
  height: 40px;
}
@media (max-width: 820px) {
  .footer__info-input:nth-child(2) {
    width: 300px;
  }
}
@media (max-width: 576px) {
  .footer__info-input:nth-child(2) {
    width: 300px;
    height: 30px;
  }
}
.footer__info-input:nth-child(1) {
  width: 300px;
}
@media (max-width: 820px) {
  .footer__info-input:nth-child(1) {
    width: 200px;
  }
}
@media (max-width: 576px) {
  .footer__info-input:nth-child(1) {
    width: 300px;
    height: 30px;
  }
}
.footer__button-email {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.footer__button {
  border-radius: 100px;
  background-color: transparent;
  color: #e9c62e;
  border: 1px solid #e9c62e;
  height: 40px;
  width: 200px;
  transition: background-color 0.5s;
}
.footer__button:hover {
  background-color: #e9c62e;
  color: black;
}
@media (max-width: 820px) {
  .footer__button {
    width: 160px;
    font-size: 16px;
    height: 30px;
  }
}
@media (max-width: 576px) {
  .footer__button {
    font-size: 14px;
    height: 30px;
    width: 140px;
  }
}
.footer__text-email {
  grid-column-start: 5;
  grid-column-end: 8;
}
@media (max-width: 1024px) {
  .footer__text-email {
    grid-column-start: 4;
    grid-column-end: 7;
  }
}
@media (max-width: 820px) {
  .footer__text-email {
    grid-column-start: 3;
    grid-column-end: 7;
  }
}
@media (max-width: 430px) {
  .footer__text-email {
    grid-column: 6;
  }
}
.footer__social-network {
  margin-left: 50px;
  grid-column-start: 9;
  grid-column-end: 13;
}
@media (max-width: 1024px) {
  .footer__social-network {
    font-size: 20px;
    grid-column-start: 8;
    grid-column-end: 13;
  }
}
@media (max-width: 960px) {
  .footer__social-network {
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .footer__social-network {
    font-size: 16px;
    margin-left: 0px;
  }
}
@media (max-width: 820px) {
  .footer__social-network {
    grid-column-start: 7;
    grid-column-end: 13;
  }
}
@media (max-width: 576px) {
  .footer__social-network {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .footer__social-network {
    margin-left: 0px;
  }
}
@media (max-width: 430px) {
  .footer__social-network {
    grid-column-start: 7;
    grid-column-end: 13;
  }
}
.footer__text-social {
  font-size: 24px;
}
@media (max-width: 1280px) {
  .footer__text-social {
    font-size: 24px;
  }
}
@media (max-width: 1024px) {
  .footer__text-social {
    font-size: 20px;
  }
}
@media (max-width: 960px) {
  .footer__text-social {
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .footer__text-social {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .footer__text-social {
    font-size: 14px;
  }
}
.footer__follow-list {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  margin-left: 30px;
}
@media (max-width: 820px) {
  .footer__follow-list {
    margin-left: 0px;
  }
}
@media (max-width: 576px) {
  .footer__follow-list {
    margin-left: 0px;
    padding-left: 0px;
  }
}
.footer__follow-link {
  border-radius: 50px;
  display: inline-block;
  height: 30px;
  width: 30px;
}
.footer__follow-link:focus {
  outline: none; /* убирает пунктирную обводку при focus */
}
.footer__svg-link {
  margin-bottom: 12px;
  height: 30px;
  width: 30px;
}
@media (max-width: 576px) {
  .footer__svg-link {
    height: 20px;
    width: 20px;
  }
}

.footer-logo__logo {
  font-family: "solena";
  font-weight: 400;
  font-size: 48px;
  padding: 10px;
}
@media (max-width: 1024px) {
  .footer-logo__logo {
    font-size: 40px;
  }
}
@media (max-width: 960px) {
  .footer-logo__logo {
    font-size: 36px;
  }
}
@media (max-width: 820px) {
  .footer-logo__logo {
    font-size: 24px;
  }
}

.footer-email__text {
  font-size: 24px;
}
@media (max-width: 1024px) {
  .footer-email__text {
    font-size: 20px;
  }
}
@media (max-width: 960px) {
  .footer-email__text {
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .footer-email__text {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .footer-email__text {
    font-size: 14px;
  }
}
.footer-email__email {
  font-size: 22px;
}
@media (max-width: 1024px) {
  .footer-email__email {
    font-size: 18px;
  }
}
@media (max-width: 960px) {
  .footer-email__email {
    font-size: 16px;
  }
}
@media (max-width: 820px) {
  .footer-email__email {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .footer-email__email {
    font-size: 12px;
  }
}
.footer-email__email:focus {
  outline: none; /* убирает пунктирную обводку при focus */
}

.bx {
  color: #e9c62e;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5px;
  margin-left: -2px;
  font-size: 24px;
  text-decoration: none;
}
@media (max-width: 576px) {
  .bx {
    font-size: 18px;
    margin-top: 2px;
  }
}

a:hover {
  text-decoration: none;
  outline: none;
  color: inherit; /* наследует цвет текста */
}

.main {
  z-index: 10;
  position: sticky;
}
.main__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main__block {
  width: 1360px;
  margin-top: 150px;
  border-radius: 15px;
  background-color: rgba(53, 49, 49, 0.5);
}
@media (max-width: 1024px) {
  .main__block {
    width: 100%;
  }
}
.main__main-text {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .main__main-text {
    font-size: 18px;
    font-weight: 600;
  }
}
@media (max-width: 820px) {
  .main__main-text {
    font-size: 16px;
    font-weight: 500;
  }
}
@media (max-width: 576px) {
  .main__main-text {
    font-size: 14px;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .main__main-text {
    font-size: 10px;
  }
}
.main__text {
  color: white;
  font-weight: 600;
}
.main__text-descr {
  color: white;
  font-size: 20px;
  margin: 15px 0px 15px 0px;
}
@media (max-width: 820px) {
  .main__text-descr {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .main__text-descr {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .main__text-descr {
    font-size: 12px;
  }
}
.main #yellow {
  color: var(--yellow-color);
}
.main #main-w {
  width: 500px;
}
@media (max-width: 1024px) {
  .main #main-w {
    width: 100%;
  }
}
.main__linia {
  width: 160px;
  height: 1px;
  border-top: 2px solid var(--yellow-color);
}
@media (max-width: 576px) {
  .main__linia {
    width: 60%;
  }
}
@media (max-width: 576px) {
  .main__linia {
    width: 80%;
    margin-left: 35px;
  }
}
.main__block-descr {
  display: flex;
  align-items: center;
}
@media (max-width: 576px) {
  .main__block-descr {
    display: block;
  }
}
.main__descr-img {
  width: 500px;
  height: auto;
  border-radius: 0px 15px 0px 15px;
}
@media (max-width: 1024px) {
  .main__descr-img {
    max-width: 450px;
    height: auto;
  }
}
@media (max-width: 820px) {
  .main__descr-img {
    max-width: 320px;
  }
}
@media (max-width: 576px) {
  .main__descr-img {
    max-width: 220px;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .main__descr-img {
    max-width: 420px;
    width: 100%;
    border-radius: 0px;
  }
}
.main__img {
  border-radius: 0px 15px 0px 15px;
}
.main__descr-text {
  margin-left: 100px;
}
@media (max-width: 1024px) {
  .main__descr-text {
    margin-left: 50px;
  }
}
@media (max-width: 820px) {
  .main__descr-text {
    margin-left: 25px;
  }
}
@media (max-width: 576px) {
  .main__descr-text {
    margin-left: 0px;
    text-align: center;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contaiiner {
  background-color: rgba(53, 49, 49, 0.5);
  width: 1360px;
  height: 700px;
  border-radius: 15px;
  position: sticky;
  margin-top: 70px;
}
@media (max-width: 1024px) {
  .contaiiner {
    height: 750px;
  }
}
@media (max-width: 960px) {
  .contaiiner {
    height: auto;
  }
}
@media (max-width: 576px) {
  .contaiiner {
    margin-top: 30px;
  }
}
@media (max-width: 430px) {
  .contaiiner {
    height: auto;
  }
}
@media (max-width: 320px) {
  .contaiiner {
    height: auto;
  }
}

.heading {
  text-align: center;
  color: #454343;
  font-size: 48px;
  font-weight: 600;
  position: relative;
  margin-bottom: 70px;
  padding: 20px;
  z-index: 11;
}
@media (max-width: 1024px) {
  .heading {
    font-size: 35px;
  }
}
@media (max-width: 960px) {
  .heading {
    font-size: 36px;
  }
}
@media (max-width: 820px) {
  .heading {
    font-size: 24px;
  }
}
@media (max-width: 576px) {
  .heading {
    font-size: 30px;
  }
}

.white-heading {
  color: #ffffff;
}

.heading:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  height: 40px;
  width: 180px;
  border-radius: 4px;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-position: center;
}

.white-heading:after {
  background: url(https://i.ibb.co/d7tSD1R/heading-line-white.png);
  background-repeat: no-repeat;
  background-position: center;
}

.heading span {
  font-size: 18px;
  display: block;
  font-weight: 500;
}

.white-heading span {
  color: #ffffff;
}

/*-----Testimonial-------*/
.testimonial:after {
  position: absolute;
  top: 0 !important;
  left: 0;
  content: " ";
  background-size: 100% 100px;
  width: 100%;
  height: 100px;
  float: left;
  z-index: 99;
}

.testimonial {
  min-height: 375px;
  position: relative;
  padding-top: 50px;
  padding-bottom: 100px;
  background-position: center;
  background-size: cover;
}

#testimonial4 .carousel-inner:hover {
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

#testimonial4 .carousel-inner:active {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

#testimonial4 .carousel-inner .item {
  overflow: hidden;
}

.testimonial4_indicators .carousel-indicators {
  left: 0;
  margin: 0;
  width: 100%;
  font-size: 0;
  height: 20px;
  bottom: 15px;
  padding: 0 5px;
  cursor: e-resize;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.testimonial4_text {
  font-size: 28px;
}
@media (max-width: 576px) {
  .testimonial4_text {
    font-size: 20px;
  }
}

.testimonial4_slide {
  background-color: rgba(88, 74, 74, 0.678);
  border-radius: 15px;
}

.testimonial4_indicators .carousel-indicators li {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  text-indent: 0;
  margin: 2px 3px;
  cursor: pointer;
  display: inline-block;
  background: #ffffff;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.testimonial4_indicators .carousel-indicators .active {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  margin: 2px 3px;
  background-color: #9dd3af;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.testimonial4_indicators .carousel-indicators::-webkit-scrollbar {
  height: 3px;
}

.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb {
  background: #eeeeee;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.testimonial4_control_button .carousel-control {
  top: 175px;
  opacity: 1;
  width: 40px;
  bottom: auto;
  height: 40px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 700;
  overflow: hidden;
  line-height: 38px;
  text-shadow: none;
  text-align: center;
  position: absolute;
  background: transparent;
  border: 2px solid #ffffff;
  text-transform: uppercase;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
  transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
}

.testimonial4_control_button .carousel-control.left {
  left: 7%;
  top: 50%;
  right: auto;
}

.testimonial4_control_button .carousel-control.right {
  right: 7%;
  top: 50%;
  left: auto;
}

.testimonial4_control_button .carousel-control.left:hover,
.testimonial4_control_button .carousel-control.right:hover {
  color: #000;
  background: #fff;
  border: 2px solid #fff;
}

.testimonial4_header {
  top: 0;
  left: 0;
  bottom: 0;
  width: 550px;
  display: block;
  margin: 30px auto;
  text-align: center;
  position: relative;
}

.testimonial4_header h4 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 576px) {
  .testimonial4_header h4 {
    font-size: 20px;
  }
}

.testimonial4_slide {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70%;
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
}

.testimonial4_slide img {
  top: 0;
  left: 0;
  right: 0;
  width: 136px;
  height: 136px;
  margin: auto;
  display: block;
  color: #f2f2f2;
  font-size: 18px;
  line-height: 46px;
  text-align: center;
  position: relative;
  border-radius: 50%;
  box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
}

.testimonial4_slide p {
  color: #ffffff;
  font-size: 18px;
  line-height: 1.4;
  margin: 40px 0 20px 0;
}
@media (max-width: 960px) {
  .testimonial4_slide p {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .testimonial4_slide p {
    font-size: 14px;
  }
}
@media (max-width: 430px) {
  .testimonial4_slide p {
    font-size: 12px;
  }
}

.testimonial4_slide h4 {
  color: #ffffff;
  font-size: 24px;
}
@media (max-width: 576px) {
  .testimonial4_slide h4 {
    font-size: 20px;
  }
}

.testimonial .carousel {
  padding-bottom: 50px;
}

.testimonial .carousel-control-next-icon, .testimonial .carousel-control-prev-icon {
  width: 35px;
  height: 35px;
}

/* ------testimonial  close-------*/
.services {
  z-index: 10;
  position: sticky;
}
.services__inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.services__block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 1360px;
  margin-top: 150px;
  border-radius: 15px;
  background-color: rgba(53, 49, 49, 0.5);
}
@media (max-width: 576px) {
  .services__block {
    margin-top: 80px;
  }
}
.services__text-main {
  text-align: center;
  color: #fff;
  font-size: 40px;
  padding-bottom: 20px;
  padding-top: 20px;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .services__text-main {
    font-size: 35px;
  }
}
@media (max-width: 820px) {
  .services__text-main {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .services__text-main {
    font-size: 25px;
  }
}
@media (max-width: 430px) {
  .services__text-main {
    font-size: 20px;
  }
}
.services__block-cards {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  padding: 20px;
}
.services__cards {
  grid-column: span 3;
  background-color: rgba(88, 74, 74, 0.678);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
@media (max-width: 1024px) {
  .services__cards {
    display: flex;
    align-items: center;
    grid-column: 4 span;
  }
}
@media (max-width: 768px) {
  .services__cards {
    grid-column: 6 span;
  }
}
@media (max-width: 375px) {
  .services__cards {
    grid-column: 12 span;
  }
}
@media (max-width: 1024px) {
  .services__img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    height: auto;
  }
}
@media (max-width: 820px) {
  .services__img {
    max-width: 200px;
  }
}
@media (max-width: 576px) {
  .services__img {
    max-width: 150px;
    width: 100%;
  }
}
.services__buttons {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.block__button-window {
  cursor: pointer;
  height: 50px;
  width: 200px;
  border-radius: 50px;
  border: 2px solid var(--gold-color);
  transition: all 300ms;
  font-size: 20px;
  font-weight: 600;
  color: var(--black-color);
  background-color: var(--gold-color);
}
@media (max-width: 1024px) {
  .block__button-window {
    width: 160px;
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .block__button-window {
    font-size: 16px;
    width: 140px;
    height: 40px;
  }
}
@media (max-width: 576px) {
  .block__button-window {
    width: 120px;
    height: 30px;
    font-size: 14px;
  }
}

.block__button-window:hover {
  color: #fff;
  background-color: transparent;
}

.block__button-question {
  cursor: pointer;
  height: 50px;
  width: 240px;
  border-radius: 50px;
  border: 2px solid var(--gold-color);
  background-color: transparent;
  transition: all 300ms;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 1024px) {
  .block__button-question {
    width: 200px;
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .block__button-question {
    font-size: 16px;
    width: 160px;
    height: 40px;
  }
}
@media (max-width: 576px) {
  .block__button-question {
    font-size: 14px;
    width: 140px;
    height: 30px;
  }
}

.block__button-question:hover {
  color: var(--black-color);
  background-color: var(--gold-color);
}

.block__button-window {
  cursor: pointer;
  height: 50px;
  width: 200px;
  border-radius: 50px;
  border: 2px solid var(--gold-color);
  transition: all 300ms;
  font-size: 20px;
  font-weight: 600;
  color: var(--black-color);
  background-color: var(--gold-color);
}
@media (max-width: 1024px) {
  .block__button-window {
    width: 160px;
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .block__button-window {
    font-size: 16px;
    width: 140px;
    height: 40px;
  }
}
@media (max-width: 576px) {
  .block__button-window {
    font-size: 14px;
    width: 120px;
    height: 30px;
  }
}

.block__button-window:hover {
  color: #fff;
  background-color: transparent;
}

.block__button-question {
  cursor: pointer;
  height: 50px;
  width: 240px;
  border-radius: 50px;
  border: 2px solid var(--gold-color);
  background-color: transparent;
  transition: all 300ms;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 1024px) {
  .block__button-question {
    width: 200px;
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .block__button-question {
    font-size: 16px;
    width: 160px;
    height: 40px;
  }
}
@media (max-width: 576px) {
  .block__button-question {
    font-size: 14px;
    width: 140px;
    height: 30px;
  }
}

.block__button-question:hover {
  color: var(--black-color);
  background-color: var(--gold-color);
}

.block__button-bloc {
  opacity: 0; /* Добавляем свойство opacity со значением 0 */
  transition: opacity 0.3s ease; /* Добавляем свойство transition для свойства opacity */
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  min-width: 1000px;
  width: 1000px;
  height: auto;
  padding: 20px;
  z-index: 13;
  border-radius: 15px;
}
.block__button-bloc p {
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .block__button-bloc {
    width: 100%;
    min-width: 320px;
  }
}
.block__close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

.show {
  display: block;
}

.block__block-inf {
  display: flex;
  align-items: center;
}
@media (max-width: 430px) {
  .block__block-inf {
    flex-direction: column;
    align-items: center;
  }
}
.block__block-img {
  min-width: 350px;
  max-width: 350px;
  height: auto;
}
@media (max-width: 1024px) {
  .block__block-img {
    min-width: 100px;
    max-width: 220px;
    width: 100%;
  }
}
.block__block-information {
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 0px 30px 0px 30px;
  max-width: 540px;
}
@media (max-width: 430px) {
  .block__block-information {
    align-items: center;
    text-align: center;
  }
}
.block__info-block {
  font-size: 20px;
  font-weight: 500;
}
@media (max-width: 1024px) {
  .block__info-block {
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .block__info-block {
    font-size: 16px;
  }
}
.block__block-button {
  cursor: pointer;
  height: 50px;
  width: 200px;
  border-radius: 50px;
  border: 2px solid var(--gold-color);
  transition: all 300ms;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: transparent;
}
@media (max-width: 1024px) {
  .block__block-button {
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .block__block-button {
    font-size: 16px;
    width: 160px;
    height: 40px;
  }
}
.block__block-button:hover {
  color: var(--black-color);
  background-color: var(--gold-color);
}
.block__main-list {
  list-style-type: square;
  font-size: 20px;
}
@media (max-width: 820px) {
  .block__main-list {
    font-size: 16px;
  }
}
.block #list {
  gap: 10px;
}
@media (max-width: 430px) {
  .block #list {
    text-align: start;
  }
}
.services__block-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.service-page__main-text {
  text-align: center;
  color: var(--light-color);
  font-size: 40px;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .service-page__main-text {
    font-size: 35px;
  }
}
@media (max-width: 820px) {
  .service-page__main-text {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .service-page__main-text {
    font-size: 25px;
  }
}
.service-page__main-list {
  color: var(--light-color);
  font-size: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style-type: square;
  padding-left: 20px;
  width: 530px;
}
@media (max-width: 1024px) {
  .service-page__main-list {
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .service-page__main-list {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .service-page__main-list {
    font-size: 14px;
    width: 100%;
  }
}
.service-page__info-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--light-color);
}
.service-page__info-text {
  font-size: 20px;
  margin: 15px 0px 10px 0px;
}
@media (max-width: 1024px) {
  .service-page__info-text {
    font-size: 18px;
  }
}
@media (max-width: 820px) {
  .service-page__info-text {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .service-page__info-text {
    font-size: 14px;
  }
}
.service-page__info-input {
  width: 530px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid var(--gold-color);
  padding-left: 10px;
}
@media (max-width: 576px) {
  .service-page__info-input {
    width: 100%;
  }
}
.service-page #textarea {
  width: 530px;
  height: 200px;
  border-radius: 10px;
}
@media (max-width: 576px) {
  .service-page #textarea {
    width: 100%;
  }
}
.service-page__button-email {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 30px 0px;
}
.service-page__button {
  cursor: pointer;
  height: 50px;
  width: 300px;
  border-radius: 50px;
  border: 2px solid var(--gold-color);
  transition: all 300ms;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: transparent;
}
@media (max-width: 820px) {
  .service-page__button {
    font-size: 16px;
    width: 200px;
    height: 40px;
  }
}
@media (max-width: 576px) {
  .service-page__button {
    font-size: 14px;
  }
}
.service-page__button:hover {
  color: var(--black-color);
  background-color: var(--gold-color);
}

.header-blog {
  z-index: 13;
  position: fixed;
  top: 0;
  scroll-behavior: smooth;
  width: 100%;
  padding: 20px 0px 20px 0px;
  background-color: var(--black-color);
  border-bottom: 2px solid #1e1b3d;
}
.header-blog__logo {
  height: auto;
  width: 285px;
  cursor: pointer;
}
.navbar-blog {
  padding: 0px;
}
.navbar-blog__menu {
  padding: 0px;
  margin-right: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
}
@media (max-width: 768px) {
  .navbar-blog__menu {
    margin-right: 0px;
    font-size: 16px;
  }
}
.navbar-blog__item {
  color: var(--light-color);
  transition: all 200ms;
}
.navbar-blog__item:hover {
  color: var(--gold-color);
}
.navbar-blog__link {
  cursor: pointer;
  color: var(--light-color);
  transition: all 300ms;
}

.header-blog__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-blog__content-logo {
  margin-right: 20px;
}

.header-blog__logo-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-blog__content-navbar {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-blog__menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-blog__item {
  margin-right: 20px;
}

.navbar-blog__link:focus {
  outline: none;
}

.band {
  padding: 170px 0px 100px 0px;
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
}
@media (min-width: 30em) {
  .band {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 60em) {
  .band {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  background: rgba(53, 49, 49, 0.5);
  text-decoration: none;
  color: var(--gold-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  top: 0;
  transition: all 0.1s ease-in;
}
.card:hover {
  top: -2px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
}
.card article {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card h1 {
  font-size: 20px;
  margin: 0;
  color: #ffffff;
}
.card p {
  flex: 1;
  line-height: 1.4;
}
.card span {
  font-size: 12px;
  font-weight: bold;
  color: #cacaca;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 2em 0 0 0;
}
.card .thumb {
  padding-bottom: 60%;
  background-size: cover;
  background-position: center center;
}

@media (min-width: 60em) {
  .item-1 {
    grid-column: 1/span 2;
  }
  .item-1 h1 {
    font-size: 24px;
  }
}

.news1 {
  z-index: 10;
  position: sticky;
  padding-bottom: 100px;
}
.news1__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.news1__block {
  justify-content: center;
  width: 1360px;
  margin-top: 150px;
  border-radius: 15px;
  background-color: rgba(53, 49, 49, 0.89);
}
@media (max-width: 1360px) {
  .news1__block {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .news1__block {
    display: block;
  }
}
@media (max-width: 576px) {
  .news1__block {
    margin-top: 80px;
  }
}
.news1__block-descr {
  color: var(--light-color);
  width: 100%;
}
.news1__main-text {
  text-align: center;
  font-size: 40px;
  margin: 20px 0px 20px 0px;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .news1__main-text {
    font-size: 35px;
  }
}
@media (max-width: 820px) {
  .news1__main-text {
    font-size: 30px;
    font-weight: 600;
  }
}
@media (max-width: 576px) {
  .news1__main-text {
    font-size: 25px;
  }
}
@media (max-width: 430px) {
  .news1__main-text {
    font-size: 20px;
  }
}
.news1__descr-text {
  font-size: 20px;
  padding: 20px 20px 0px 20px;
  font-weight: 400;
  text-align: justify;
  line-height: 1.2;
}
@media (max-width: 1360px) {
  .news1__descr-text {
    line-height: 1.5;
  }
}
@media (max-width: 1024px) {
  .news1__descr-text {
    font-size: 18px;
    line-height: 1.5;
  }
}
@media (max-width: 820px) {
  .news1__descr-text {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .news1__descr-text {
    font-size: 14px;
  }
}
@media (max-width: 430px) {
  .news1__descr-text {
    font-size: 12px;
  }
}

.page__body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--vh);
}