.main-wrapper {

    // .main-wrapper__section

    &__section {
    }
}
.autor {
    z-index: 10;
    position: sticky;


    &__list {
        text-align: start;
    }

    // .autor__list-text

    &__list-text {
        font-size: 20px;
        padding: 5px;
        list-style-type: square;
        @include xxxl{
         font-size: 18px;   
        }
        @include xl{
            font-size: 16px;   
           }
           @include sm{
            font-size: 14px;
           }
    }



    // .autor__inner

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    // .autor__block

    &__block {
        display: flex;
        justify-content: center;
        

        width: 1360px;
        margin-top: 150px;
        border-radius: 15px;
        background-color: rgba(53, 49, 49, 0.5);
        @include xxxxxl{
            width: 100%;
        }
        @include lg{
            display: block;
        }
        @include md{
           margin-top: 80px;
        }
    }

    // .autor__block-descr

    &__block-descr {
       color: var(--light-color);
       width: 100%;
    }

    // .autor__main-text

    &__main-text {
        text-align: center;
       font-size: 40px;
       margin: 20px 0px 5px 0px;
       font-weight: 600;
       @include xxxl{
        font-size: 35px; 
         
       }
       @include xl{
        font-size: 30px;   
        font-weight: 600;
       }
       @include md{
        font-size: 25px;
    }
    @include sm{
        font-size: 20px;
       }
    }

    // .autor__descr-text

    &__descr-text {
        font-size: 20px;
        padding: 20px 20px 0px 20px;
        font-weight: 400;
        text-align: justify;
       line-height: 1.2;
       @include xxxxxl {
        line-height: 1.5;
       }
       @include xxxl{
        font-size: 18px;
            line-height: 1.5
       }
       @include xl{
        font-size: 16px;   
       }
       @include md{
        font-size: 14px;
    }
    @include sm{
        font-size: 12px;
       }
    }

    // .autor__descr-img

    &__descr-img {
        border-radius: 15px;
        object-fit: cover;

        width: 980px;
        height: 736px;
        @include xxxxxl{
           height: auto;
        }
        @include xxxl{
            width: 400px;
        }
        @include xl{
            width: 300px;
           }
           @include lg{
            width: 100%;
            //height: 30%;
            max-height: 800px;
        }
        @include md{
            width: 100%;
            //height: 30%;
            max-height: 600px;
        }
    }
    #obraz{
        margin-top: 50px;
    }
}
.main {

    // .main__container

    &__container {
    }
}


