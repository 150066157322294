// базовые подключения
@import "vars";
@import "mixins";
@import "fonts";
@import "settings";

// подключения компонентов страницы
@import "./components/header";
@import "./components/advantages";
@import "./components/autor";
@import "./components/background";
@import "./components/footer";
@import "./components/main";
@import "./components/reviews";
@import "./components/services";
@import "./components/header-blog";
@import "./components/blog-news";
@import "./components/news1";
// тестовые стили, удалите их
.page__body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--vh);
}
