$ss: 320px;
$gg: 375px;
$sm: 430px;
$md: 576px;
$lg: 768px;
$xl: 820px;
$xxl: 960px;
$xxxl: 1024px;
$xxxxl: 1280px; 
$xxxxxl: 1360px;
@mixin ss {
    @media (max-width: #{$ss}) {
        @content;
    }
 }
 @mixin gg {
    @media (max-width: #{$gg}) {
        @content;
    }
 }
@mixin sm {
    @media (max-width: #{$sm}) {
        @content;
    }
 }

 @mixin md {
    @media (max-width: #{$md}) {
        @content;
    }
 }
 @mixin lg {
    @media (max-width: #{$lg}) {
        @content;
    }
 }
 @mixin xl {
    @media (max-width: #{$xl}) {
        @content;
    }
 }
 @mixin xxl {
    @media (max-width: #{$xxl}) {
        @content;
    }
 }
 @mixin xxxl {
    @media (max-width: #{$xxxl}) {
        @content;
    }
 }
 @mixin xxxxl {
    @media (max-width: #{$xxxxl}) {
        @content;
    }
    
 }
 @mixin xxxxxl {
    @media (max-width: #{$xxxxxl}) {
        @content;
    }
    
 }