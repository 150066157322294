.main-wrapper {

    // .main-wrapper__section

    &__section {
    }
}
.services {
    z-index: 10;
    position: sticky;
    // .services__inner

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
       
    }

    // .services__block

    &__block {
        display: flex;
        justify-content: center;
        flex-direction: column;

        width: 1360px;
        margin-top: 150px;
        border-radius: 15px;
        background-color: rgba(53, 49, 49, 0.5);
        @include md{
            margin-top: 80px;
        }
    }

    // .services__main-text

    &__main-text {
    }

    // .services__text-main

    &__text-main {
        text-align: center;
        color: #fff;
        font-size: 40px;
        padding-bottom: 20px;
        padding-top: 20px;
        font-weight: 600;
        @include xxxl{
         font-size: 35px;   
        }
        @include xl{
            font-size: 30px;   
           }
           @include md{
            font-size: 25px;
           }
           @include sm{
            font-size: 20px;
           }
    }

    // .services__block-cards

    &__block-cards {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 20px;
        padding: 20px;
    }

    // .services__cards

    &__cards {
        grid-column: span 3;
        background-color:rgba(88, 74, 74, 0.678);
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        @include xxxl{
            display: flex;
            align-items: center;
         grid-column: 4 span;   
        }
        @include lg{
            grid-column: 6 span;
            
        }
        @include gg{
          grid-column: 12 span;
           }
    }

    // .services__img

    &__img {
        
        @include xxxl{
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 250px;
            
         height: auto;   
        }
        @include xl{
            max-width: 200px;
           }
           @include md{
            max-width: 150px;
            width: 100%;
           }
    }

    // .services__button-window
    &__buttons{
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
   
}
.main {

    // .main__container

    &__container {
    }
}
.block__button-window {
        cursor: pointer;
        height: 50px;
        width: 200px;
        border-radius: 50px;
        border: 2px solid var(--gold-color);
        
        transition: all 300ms;
        font-size: 20px;
        font-weight: 600;
        
        color:var(--black-color);
       
       
        background-color: var(--gold-color);
        @include xxxl{
            width: 160px;
            font-size: 18px;   
        }
        @include xl{
            font-size: 16px;  
            width: 140px;
            height: 40px; 
        }
        @include md{
            width: 120px;
            height: 30px;
            font-size: 14px;
           }
    }
    .block__button-window:hover {
        color: #fff;
        background-color: transparent;
    }

    // .services__button-question

    .block__button-question {

        cursor: pointer;
        height: 50px;
        width: 240px;
        border-radius: 50px;
        border: 2px solid var(--gold-color);
        background-color: transparent;
        transition: all 300ms;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        @include xxxl{
            width: 200px;
            font-size: 18px;   
           }
           @include xl{
            font-size: 16px;  
            width: 160px;
            height: 40px; 
        }
        @include md{
            font-size: 14px;
            width: 140px;
            height: 30px;
           }
    }
    .block__button-question:hover {
        color:var(--black-color);
       
       
        background-color: var(--gold-color);
        
    }


    .block__button-window {
        cursor: pointer;
        height: 50px;
        width: 200px;
        border-radius: 50px;
        border: 2px solid var(--gold-color);
        
        transition: all 300ms;
        font-size: 20px;
        font-weight: 600;
        
        color:var(--black-color);
       
       
        background-color: var(--gold-color);
        @include xxxl{
            width: 160px;
            font-size: 18px;   
           }
           @include xl{
            font-size: 16px;  
            width: 140px;
            height: 40px; 
        }
        @include md{
            font-size: 14px;
            width: 120px;
            height: 30px;
           }
    }
    .block__button-window:hover {
        color: #fff;
        background-color: transparent;
    }

    // .services__button-question

    .block__button-question {

        cursor: pointer;
        height: 50px;
        width: 240px;
        border-radius: 50px;
        border: 2px solid var(--gold-color);
        background-color: transparent;
        transition: all 300ms;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        @include xxxl{
            width: 200px;
            font-size: 18px;   
           }
           @include xl{
            font-size: 16px;  
            width: 160px;
            height: 40px; 
        }
        @include md{
            font-size: 14px;
            width: 140px;
            height: 30px;
           }
    }
    .block__button-question:hover {
        color:var(--black-color);
       
       
        background-color: var(--gold-color);
        
    }





.block {

    // .block__button-bloc

    &__button-bloc {
        opacity: 0; /* Добавляем свойство opacity со значением 0 */
    transition: opacity 0.3s ease; /* Добавляем свойство transition для свойства opacity */
        display: none;
     
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        min-width: 1000px;
        width: 1000px;
        height: auto;
        padding: 20px;
       z-index: 13;
        
        border-radius: 15px;
        p {
            margin-bottom: 10px;
        }
        @include xxxl{
           width: 100%;   
           min-width: 320px;
           }

    }

    // .block__close

    &__close {
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 24px;
        cursor: pointer;
        color: #fff;
    }
}
.show {
    display: block;
  
  
}
.services {

		// .services__buttons

		&__buttons {
		}
}
.block {

		// .block__button-bloc

		&__button-bloc {
		}

		// .block__close

		&__close {
		}

		// .block__block-inf

		&__block-inf {
            display: flex;
            align-items: center;
            @include sm{
                flex-direction: column;
                align-items: center;
               }
        }
		

		// .block__img-block

		&__img-block {
            
		}

		// .block__block-img

		&__block-img {
            min-width: 350px;
            max-width: 350px;
            height: auto;
            @include xxxl{
                min-width: 100px;
            max-width: 220px;
                width: 100%;
               }
		}

		// .block__block-information

		&__block-information {
            color: var(--light-color);
            display: flex;
            flex-direction: column;
            gap: 50px;
            padding: 0px 30px 0px 30px;
            max-width: 540px;
            @include sm{
                align-items: center;
                text-align: center;
               }
            
		}

		// .block__info-block

		&__info-block {
            font-size: 20px;
            font-weight: 500;
            @include xxxl{
                
                font-size: 18px;   
               }
               @include xl{
                font-size: 16px;  
                
            }
		}

		// .block__block-button

		&__block-button {
            cursor: pointer;
            height: 50px;
            width: 200px;
            border-radius: 50px;
            border: 2px solid var(--gold-color);
            
            transition: all 300ms;
            font-size: 18px;
            font-weight: 600;
            color: #fff;
            background-color: transparent;
            @include xxxl{
                
                font-size: 18px;   
               }
               @include xl{
                font-size: 16px;  
               width: 160px;
               height: 40px;
            }
        }

        &__block-button:hover {
            color:var(--black-color);
           
           
            background-color: var(--gold-color);
		}

		// .block__button-window

		&__button-window {
		}

		// .block__button-question

		&__button-question {
		}
}
.block {

    // .block__main-list

    &__main-list {
        list-style-type: square;
        font-size: 20px;
        @include xl{
            font-size: 16px;  
            
        }
    }
    #list{
        gap: 10px;
        @include sm{
           text-align: start;
           }
    }

    // .block__block-list

    &__block-list {
    }
}



.services {

    // .services__block-form

    &__block-form {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
    }
}
.service-page {

    // .service-page__clients

    &__clients {
    }

    // .service-page__clietns-text

    &__clietns-text {
    }

    // .service-page__main-text

    &__main-text {
        text-align: center;
        color: var(--light-color);
        font-size: 40px;
        font-weight: 600;
        @include xxxl{
           
            font-size: 35px;   
           }
           @include xl{
            font-size: 30px;  
           
        }
        @include md{
            font-size: 25px;
           }
    }

    // .service-page__main-list

    &__main-list {
        color: var(--light-color);
        font-size: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        list-style-type: square;
        padding-left: 20px;
        width: 530px;
        @include xxxl{
           
            font-size: 18px;   
           }
           @include xl{
            font-size: 16px;  
            
        }
        @include md{
            font-size: 14px;
            width: 100%;
           }
    }

    // .service-page__info

    &__info {
    }

    // .service-page__info-form

    &__info-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--light-color);
    }

    // .service-page__info-text

    &__info-text {
        font-size: 20px ;
       margin: 15px 0px 10px 0px;
       @include xxxl{
    
        font-size: 18px;   
       }
       @include xl{
        font-size: 16px;  
        
    }
    @include md{
        font-size: 14px;
       }
    }

    // .service-page__info-input

    &__info-input {
        width: 530px;
        height: 50px;
        border-radius: 50px;
        border:2px solid var(--gold-color) ;
        padding-left: 10px;
        @include md{
            width: 100%;
           
            
           }
    }
    #textarea{
        width: 530px;
        height: 200px;
        border-radius: 10px;
        @include md{
           width: 100%;
           }
        
    }
    
    // .service-page__button-email

    &__button-email {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px 30px 0px;
    }

    // .service-page__button

    
    &__button {
       
     cursor: pointer;
     height: 50px;
     width: 300px;
     border-radius: 50px;
     border: 2px solid var(--gold-color);
     
     transition: all 300ms;
     font-size: 18px;
     font-weight: 600;
     color: #fff;
     background-color: transparent;
     @include xl{
        font-size: 16px;  
        width: 200px;
        height: 40px;
    }
    @include md{
        font-size: 14px;
       }

    }
    &__button:hover {
        color:var(--black-color);


        background-color: var(--gold-color);
    }
}
.services-page {

    // .services-page__list-text

    &__list-text {
    }
}
